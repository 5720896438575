<template>
    <div class="w-100">
        <!-- Header -->
        <div class="text-center mb-5">
            <p class="mb-3">
                <i class="fa fa-2x fa-circle-notch text-primary-light"></i>
            </p>
            <h1 class="fw-bold mb-2">
                Reset Password
            </h1>
            <p class="fw-medium text-muted">
                Ubah password anda, pastikan hanya anda yang mengetahui password ini.
            </p>
        </div>
        
        <div class="row g-0 justify-content-center">
            <div class="col-sm-8 col-xl-8">
                <form class="js-validation-reminder" @submit.prevent="resetPassword">
                    <div class="mb-4">
                        <input type="password" class="form-control form-control-lg form-control-alt py-3" placeholder="Password Baru" v-model="auth.password" required>
                    </div>
                    <div class="mb-4">
                        <input type="password" class="form-control form-control-lg form-control-alt py-3" placeholder="Ulangi Password Baru" v-model="auth.password_confirmation" required>
                    </div>
                    <div class="text-center">
                        <button type="submit" class="btn btn-lg btn-alt-primary">
                            Reset Password
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <!-- END Reminder Form -->
    </div>

</template>

<script>

import axios from 'axios'
export default {
    components: {

    },

    data: () => ({
        hasVisiblePassword: false,
        auth: {
            password_confirmation: '',
            password: ''
        },

        loading: {
            submit: false
        }

    }),

    methods: {
        resetPassword() {
            this.loading.submit = true

            return new Promise(() => {
                axios.post('resetPassword', {

                    email: localStorage.getItem('requested_email'),
                    password_confirmation: this.auth.password_confirmation,
                    password: this.auth.password,

                }, {
                    headers: {
                        'Access-Control-Allow-Headers': '*',
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then(response => {

                    this.openNotification('top-right', 'success', '10000', 'Berhasil', response.data.message);
                    this.$router.push({ name: 'login' })
                    this.loading.submit = false

                })

                    .catch(error => {
                        this.openNotification('top-right', 'danger', '10000', 'Autentikasi Gagal', error.response.data.message);
                        this.loading.submit = false
                    })

            })
        },

        openNotification(position = null, color, duration, title, message) {
            this.$vs.notification({
                color,
                position,
                duration,
                title: title,
                text: message
            })
        },
    }
}

</script>

<style scoped>
@import '../../../public/oneui/assets/css/oneui.min.css';
</style>
